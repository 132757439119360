$(document).ready(function(){
  var elements = document.getElementsByClassName('js-toggle-more-content');
  var element = elements[0];

  $(elements).hide();

  $('.js-toggle-more-btn').click(function(){
    $(this).next('.js-toggle-more-content').slideToggle('slow');
  });
});
