$(document).ready(function() {
  $(window).scroll(function() {
      if($(this).scrollTop() > 1500) {
          $("#js-scroll-to-top-btn").fadeIn(1000)
      } else {
          $("#js-scroll-to-top-btn").fadeOut(1000);
      }
  });

  //Click event to scroll to top
  $("#js-scroll-to-top-btn").click(function() {
      $('html, body').animate({scrollTop : 0}, 500);
      return false;
  });
})