$(document).ready(function() {
  $('#js-cookies-banner-accept-btn').click(function() {
    // set _allow_cookies to one year
    document.cookie = "_allow_cookies=true; max-age=31536000; path=/";
    $('#js-cookies-banner').fadeOut();
    // location.reload(true);
  });
  $('#js-cookies-banner-reject-btn').click(function() {
    // reject _allow_cookies
    // document.cookie = "_allow_cookies=false; max-age=31536000; path=/";
    document.cookie = "_reject_cookies=true; max-age=31536000; path=/";
    $('#js-cookies-banner').fadeOut();
  });
})