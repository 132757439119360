$(document).ready(function() {
  
  // Pages
  const $pages = $('.js-journal-page')
  const pages_count = $pages.length
  const first_page_id = 0 
  const last_page_id = parseInt(pages_count, 10) - 1
  const start_page_id = last_page_id
  const $start_page = $('#js-journal-page-' + start_page_id)
  
  // Buttons
  const $nextButton = $('#js-next-journal-page-btn')
  const $prevButton = $('#js-prev-journal-page-btn')

  // Reset active Page
  $pages.removeClass('active')
  $start_page.addClass('active')

  // Set current ID to last_page on page load
  let current_id = start_page_id
  let $current_page = $('#js-journal-page-' + current_id)
  
  if(current_id == last_page_id ) {
    $nextButton.hide()
  };

  if(current_id == first_page_id ) {
    $prevButton.hide()
  };
  
  // Next
  let next_id = parseInt(current_id, 10) + 1
  
  // Prev
  let prev_id = parseInt(current_id, 10) - 1

  // Buttons
  $prevButton.click(function(){
    current_id--
    setCurrentPage(current_id);

    if(prev_id > first_page_id){
      prev_id--
    } else {
      prev_id = last_page_id
    }
    next_id--

    toggleButtonVisibility();
  });

  $nextButton.click(function(){    
    current_id++
    setCurrentPage(current_id);

    if(next_id < last_page_id){
      next_id++
    } else {
      next_id = first_page_id
    }
    prev_id++

    toggleButtonVisibility();
  });

  function setCurrentPage(id) {
    $current_page.removeClass('active')
    $current_page = $('#js-journal-page-' + id)
    $current_page.addClass('active')
  }

  function toggleButtonVisibility() {
    // Show prevButton if current page is last page and more than one page
    if(current_id == last_page_id && pages_count > 1) {
      $prevButton.show()
    }
    // Show nextButton if current page is first page and more than one page
    if(current_id == first_page_id && pages_count > 1) {
      $nextButton.show()
    }

    if(current_id == last_page_id) {
      $nextButton.hide()
    } else if(current_id == first_page_id) {
      $prevButton.hide()
    } else {
      $prevButton.show()
      $nextButton.show()
    }
  }
});