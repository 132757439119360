$(document).ready(function() {
  const media = $('#js-media-player')
  
  // Click event to play media
  $('.js-media-player-play-btn').click(function() {
    media[0].play();
  });

  // Click event to pause media
  $('.js-media-player-pause-btn').click(function() {
    media[0].pause();
  });

  // Click event to stop media
  $('.js-media-player-stop-btn').click(function() {
    media[0].pause()
    media[0].currentTime = 0;
  });

  // Click event to play/pause media on Image clicked
  $('.js-media-player-img').click(function() {
    if(media[0].paused) {
      media[0].play();
    } else {
      media[0].pause();
    }
  });
})